import * as React from 'react';
import '../styles/components/container.pcss';

interface ContainerProps { }

interface ContainerState {
    primaryLocked: Boolean;
    secondaryLocked: Boolean;
    almazrahdropLocked: Boolean;
    ashikaislanddropLocked: Boolean;
    ruleLocked: Boolean;
    weapon1: String;
    weapon2: String;
    almazrahdrop: String;
    ashikaislanddrop: String;
    rule: String;
}

export class Container extends React.Component<ContainerProps, ContainerState> {
    public constructor(props: ContainerProps) {
        super(props);

        this.state = { 
            primaryLocked: false, 
            secondaryLocked: false, 
            almazrahdropLocked: false,
            ashikaislanddropLocked: false,
            ruleLocked: false,
            weapon1: 'None Selected', 
            weapon2: 'None Selected', 
            almazrahdrop: 'None Selected', 
            ashikaislanddrop: 'None Selected', 
            rule: 'None Selected'
        };
    }

    public render() {
        return (
            <div>
                <div className="docfox-warzone-logo" />
                <a href="https://twitch.tv/docfoxtv" className="docfox-link">TWITCH.TV/DOCFOXTV</a>

                <div className="weapon-container">
                    <div className="weapon-title">Loadout:</div>
                    <div className="weapon-roll"><span className={!this.state.primaryLocked ? "weapon-roll-title" : "locked"} onClick={()=>this.setState({primaryLocked: !this.state.primaryLocked})}>{!this.state.primaryLocked ? "Primary:": "Locked: "}</span> {this.state.weapon1} &nbsp;&nbsp;&nbsp;&nbsp;<span className={!this.state.secondaryLocked ? "weapon-roll-title" : "locked"} onClick={()=>this.setState({secondaryLocked: !this.state.secondaryLocked})}>{!this.state.secondaryLocked ? "Secondary:": "Locked: "}</span> {this.state.weapon2}</div>
                </div>
                <div className="drop-container">
                    <div className="drop-title">Drop:</div>
                    <div className="drop-roll"><span className={!this.state.almazrahdropLocked ? "drop-roll-title" : "locked"} onClick={()=>this.setState({almazrahdropLocked: !this.state.almazrahdropLocked})}>{!this.state.almazrahdropLocked ? "Al Mazrah:": "Locked: "}</span> {this.state.almazrahdrop} &nbsp;&nbsp;&nbsp;&nbsp;<span className={!this.state.ashikaislanddropLocked ? "drop-roll-title" : "locked"} onClick={()=>this.setState({ashikaislanddropLocked: !this.state.ashikaislanddropLocked})}>{!this.state.ashikaislanddropLocked ? "Ashika Island:": "Locked: "}</span> {this.state.ashikaislanddrop} </div>
                </div>
                <div className="rule-container">
                    <div className={!this.state.ruleLocked ? "rule-title" : "locked"} onClick={()=>this.setState({ruleLocked: !this.state.ruleLocked})}>{!this.state.ruleLocked ? "Rule:": "Locked: "}</div>
                    <div className="rule-roll">{this.state.rule}</div>
                </div>
                <div className="roll-button" onClick={() => this.onRoll()}>ROLL</div>
            </div>
        );
    }

    private onRoll() {
        this.setState({ 
            weapon1: this.state.primaryLocked ? this.state.weapon1 : this.weapons[Math.floor(Math.random() * this.weapons.length)], 
            weapon2: this.state.secondaryLocked ? this.state.weapon2 : this.weapons2[Math.floor(Math.random() * this.weapons2.length)], 
            almazrahdrop: this.state.almazrahdropLocked ? this.state.almazrahdrop : this.almazrahdrops[Math.floor(Math.random() * this.almazrahdrops.length)], 
            ashikaislanddrop: this.state.ashikaislanddropLocked ? this.state.ashikaislanddrop : this.ashikaislanddrops[Math.floor(Math.random() * this.ashikaislanddrops.length)], 
            rule: this.state.ruleLocked ? this.state.rule : this.rules[Math.floor(Math.random() * this.rules.length)] 
        });
    }

    private weapons = [
        'Any Assault Rifle',
        'Any SMG',
        'Any Shotgun',
        'Any LMG',
        'Any Marksman Rifle',
        'Any Sniper Rifle',
        'Any Pistol',
        'Any Launcher',
        'Any Melee',
        'Any Assault Rifle',
        'Any SMG',
        'Any Shotgun',
        'Any LMG',
        'Any Marksman Rifle',
        'Any Sniper Rifle',
        'Any Pistol',
        'Any Launcher',
        'Any Melee',
        'Any Assault Rifle',
        'Any SMG',
        'Any Shotgun',
        'Any LMG',
        'Any Marksman Rifle',
        'Any Sniper Rifle',
        'Any Pistol',
        'Any Launcher',
        'Any Melee',
        'Any Assault Rifle',
        'Picked by the Den (Twitch chat)',
        'Picked by the Den (Twitch chat)',
        'Picked by the Den (Twitch chat)',
        "Lachmann-556",
        "M4",
        "M16",
        "Kastov-74U",
        "TAQ-56",
        "STB 556",
        "Kastov 762",
        "Kastov 545",
        "M13B",
        "Chimera",
        "FTAC Recon",
        "Lachmann-762",
        "TAQ-V",
        "SO-14",
        "Lachmann Sub",
        "FSS Hurricane",
        "Fennec 45",
        "BAS-P",
        "MX9",
        "MINIBAK",
        "VEL 46",
        "PDSW-528",
        "Vaznev-9K",
        "BAS-P",
        "Expedite 12",
        "Bryson 800",
        "Bryson 890",
        "Lockwood 300",
        "556 Icarus",
        "RAPP H",
        "Sakin MG38",
        "RAAL MG",
        "HCR 56",
        "RPK",
        "LM-S",
        "Lockwood MK2",
        "SP-R 208",
        "EBR-14",
        "SA-B 50",
        "TAQ-M",
        "Signal 50",
        "LA-B 330",
        "MCPR-300",
        "SP-X 80",
        "Victus XMR",
        "Riot Shield",

    ];

    private weapons2 = [
        'Any Assault Rifle',
        'Any SMG',
        'Any Shotgun',
        'Any LMG',
        'Any Marksman Rifle',
        'Any Sniper Rifle',
        'Any Pistol',
        'Any Launcher',
        'Any Melee',
        'Any Assault Rifle',
        'Any SMG',
        'Any Shotgun',
        'Any LMG',
        'Any Marksman Rifle',
        'Any Sniper Rifle',
        'Any Pistol',
        'Any Launcher',
        'Any Melee',
        'Any Assault Rifle',
        'Any SMG',
        'Any Shotgun',
        'Any LMG',
        'Any Marksman Rifle',
        'Any Sniper Rifle',
        'Any Pistol',
        'Any Launcher',
        'Any Melee',
        'Any Assault Rifle',
        'Picked by the Den (Twitch chat)',
        'Picked by the Den (Twitch chat)',
        'Picked by the Den (Twitch chat)',
        "Lachmann-556",
        "M4",
        "M16",
        "Kastov-74U",
        "TAQ-56",
        "STB 556",
        "Kastov 762",
        "Kastov 545",
        "M13B",
        "Chimera",
        "FTAC Recon",
        "Lachmann-762",
        "TAQ-V",
        "SO-14",
        "Lachmann Sub",
        "FSS Hurricane",
        "Fennec 45",
        "BAS-P",
        "MX9",
        "MINIBAK",
        "VEL 46",
        "PDSW-528",
        "Vaznev-9K",
        "BAS-P",
        "Expedite 12",
        "Bryson 800",
        "Bryson 890",
        "Lockwood 300",
        "556 Icarus",
        "RAPP H",
        "Sakin MG38",
        "RAAL MG",
        "HCR 56",
        "RPK",
        "LM-S",
        "Lockwood MK2",
        "SP-R 208",
        "EBR-14",
        "SA-B 50",
        "TAQ-M",
        "Signal 50",
        "LA-B 330",
        "MCPR-300",
        "SP-X 80",
        "Victus XMR",
        "X12",
        "X13 Auto",
        "P890",
        ".50 GS",
        "Basilisk",
        "PILA",
        "JOKR",
        "RPG-7",
        "Strela-P",
        "Combat Knife",
        "Riot Shield",

    ];

    private almazrahdrops = [
        'Any Drop',
        'Any Drop',
        'Any Drop',
        'Any Drop',
        'Let The Den Decide (Twitch chat)',
        "Oasis",
        "Taraq Village",
        "Western Foothills",
        "Rohan Oil Refinery",
        "Al Safwa Quarry",
        "North Industrial",
        "Western Outskirts",
        "Al Mazrah City",
        "Eastern Outskirts",
        "Southern Outskirts",
        "Zarqwa Hydroelectric",
        "Mawizeh Marshlands",
        "East Suburbs",
        "Sattiq Cave Complex",
        "Hafid Port",
        "Sa'id City",
        "Northwest Mountain",
        "Zaya Observatory",
        "Al Sharim Pass",
        "Ahkdar Village",
        "El Samman Cemetery",
        "Sawah Village",
        "Sarrif Bay",
        "Al Malik Airport",
        "Al Bagra Fortress"
    ]

    private ashikaislanddrops = [
        'Any Drop',
        'Any Drop',
        'Any Drop',
        'Any Drop',
        'Let The Den Decide (Twitch chat)',
        "Town Center",
        "Beach Club",
        "Port Ashika",
        "Shipwreck",
        "Tsuki Castle",
        "Residential",
        "Uganikku Farms"
    ]

    private rules = [
        "The Den: Ask Twitch chat for a special rule",
        "Protect the President: One player is assigned president and you must protect them at all costs. If they die, it's game over (President can only use pistol & riot shield)",
        "One Chest Only: Players can only use the loot they recieve from the first chest they open (loot bags don't count",
        "Choke on it: No using Gas Masks",
        "The Amish: No using field upgrades",
        "Judge, Jury and Executioner: No buying back teammates",
        "Prison Snake: If sent to the gulag you must lay down and crawl for the duration of your fight.",
        "Field Tested: No using loadout drops, all guns must be found on the map",
        "EMP: No killstreaks (ex: no UAV, cluster strikes, etc)",
        "Lucky Duck: No special rules.",
        "Choose Wisely: You can only loot one house/building (can loot bodies).",
        "Respect the Dead: No looting bodies.",
        "Grounded: You can only loot the bottom story/floor of any building/house/structure.",
        "Vehicular Manslaughter: If you are in a vehicle you must attempt run over any player you see.",
        "Stay Low: You can only move around when crouched, prone or in a vehicle.",
        "Pick Your Shots: You cannot carry more than one mag for each weapon at a time. Drop all extra ammo",
        "Cop Mode: you cannot shoot at someone until they start shooting at you.",
        "Bird Watcher: You must go to every loadout drop you see falling.",
        "Slim Pickings: You can only loot loadout drops, get a vehicle and find that drop.",
        "Gephyrophobia: You are deathly afraid of bridges, you must swim or go around when you encounter a bridge",
        "Heads Up: You must fire at least 3 warning shots close to the enemy before hitting them.",
        "Viking Funeral: If you have a Molotov you must throw it on an enemies body after downing them in order to send them to Valhalla.",
        "No Cowards: If you are standing and you are shot at you cannot lay down or crouch until the enemy is dead or they leave.",
        "Afraid of heights: you can't go above the first story of any building.",
        "Spread Out: If in a group, all party members must pick separate locations to drop and then meet up.",
        "Carpooling Sucks: If in a group, everyone must drive their own vehicle.",
        "360 No Scope: If you have a scoped weapon, you must do a 360 before you can shoot.",
        "Roadrage: Only vehicle kills, no guns or grenades.",
        "Guardian Angel: Follow the first player/squad you find and protect them from others without letting them know that you are there, you may not harm them. If they die they you commit suicide.",
        "Paraglider: Jump in the middle of the plane path and pull your chute straight away. Glide to the furthest point away from the path.",
        "Blitz: Jump as soon as possible and dive straight down. Then hunt other players as fast as possible with your pistol",
        "COD Master: No-scoping only.",
        "Silence is Golden: No speaking to teammates only can communicate through in game movements.",
        "Farmer: Jump from plane with the AFK'ers.",
        "Low Profile: While moving on foot you can only crawl. (Once stationary any stance is allowed. Vehicles allowed.)",
        "Vape Nation: You must throw a smoke grenade every time you see an enemy.",
        "Grenadier: Your only weapons are grenades and melee.",
        "This is my Rifle: The first gun you find(besides your starting pistol), whether it be a pistol, shotgun, crossbow, etc, is the only weapon you can use for the game.",
        "Switch It Up: Every time you kill someone, you have to switch your weapon that you used to kill them, with one of their weapons.",
        "Lounge Chair: you can only get kills from a vehicle (no roadkill's, shooting from a seat)",
        "Suppressed Only: Whichever suppressed weapon you find first is now the only weapon you can use.",
        "Grave Robber: You can only keep what you kill (After the first gas circle you can only loot dead bodies you've killed).",
        "Beware the man with one gun, he knows how to use it: Only use one gun.",
        "Stay Quiet: You're only allowed to use a weapon if they have a suppressor.",
        "Hot Potato: Every grenade you see(frag, flash ,smoke, & molotov) you must pick up and immediately throw it .",
        "Entamaphobia: Cannot open doors, must either loot open structures or doors already open",
        "Hey That's Mine: you and your squad have to find a vehicle and guard it with your lives. If an enemy takes it you have to commit suicide.",
        "Trigger happy: You can only use the full automatic on weapons (if they have it) and once you start firing you can't stop un till your mag is empty.",
        "Death from Above: Take the first helicopter you see and hunt people down with it.",
        "Fly Swatter: Your sole goal in life is to take down every helicopter you see."
    ]
}
